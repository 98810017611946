
import router from '@/router';
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
  name: 'SkipMaintenance',
  components: {
  },
  setup() {
    onMounted(async () => {
      localStorage.maintenance = false;
      router.push({ name: 'Home' });
    });

    return {};
  },
});
